html {
  height: 100%;
  font-size: 10px;
  line-height: 1.3;

}

body {
  font-family: $font--family;
  background: $white;
  color: $black;
  font-weight: normal;
  height: 100%;
}

// Wrapper
.wrapper {
  max-width: $max-width;
  margin: 0 auto;
  padding: 0 2em;
  width: 100%;
  overflow: hidden;
  @include bp(1060) {
    padding: 0;

  }
}

header {
  position: relative;
  background: rgb(129,111,237);
  background: linear-gradient(to bottom, rgba(129,111,237,1) -20%,rgba(97,84,206,1) 40%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#816fed', endColorstr='#6154ce',GradientType=0 );

  @include grid-container;
  min-height: 660px;
  @include bp($tabletBP) {
    min-height: 782px;
  }
  @include bp($desktopBP) {}

  &:after {
    content: '';
    height: 41px;
    width: 100%;
    position: absolute;
    background-color: $white;
    // bottom: 33px;
    @include bp(391, max-width) {
      height: 32px;
    }
  }
}

.hero {
  position: relative;
  z-index: 99;
  .logo {
    background-color: $purple;
    width: 229px;
    height: 229px;
    border-radius: 50%;
    margin: -125px auto;
    text-align: center;

    img {
      max-width: 126px;
      padding-top: 150px;
      display: inline-block;
    }
  }

  .intro {
    margin-top: 15em;
    text-align: center;
  }




}

.video-phone {
  @include centerElement(horizontal)
  .phone {
    max-width: 387px;
    @include bp($phabletBP, max-width) {
      width:287px;
      max-width: none;
    }
  }
}

.form {
  text-align: center;
  margin-top: 2em;
  .elements {
    display: block;
  }
  .results {
    display: none;
    max-width: 500px;
    width: 100%;
    margin: 0 auto;

    img {
      width: 40px;
      display: inline-block;
      vertical-align: middle;
    }
    p {
      font-weight: bold;
      width: calc(100% - 40px);
      display: inline-block;
      vertical-align: middle;
      padding-left: 15px;
      @include bp($tabletBP, max-width) {
        padding-top: 10px;
        width: 100%;
        padding-left: 0;
      }
    }
  }

  .error {
    text-align: center;
    font-weight: normal;
    font-size: 1.2rem;
    margin-top: 5px;
    color: red;
    visibility: hidden;
  }

  .email {
    border-radius: 25px;
    width: 100%;

    padding: 10px 20px;
    border: none;
    -webkit-appearance: none;
    outline: none;
    font-size: 1.6rem;
    color:$lgrey;
    margin-bottom: 1rem;
    @include bp($tabletBP) {
      padding: 10px 115px 10px 20px;
      margin-bottom: 0rem;
      max-width: 400px;

    }
    &::placeholder {
      color:$lgrey;
      font-size: 1.6rem;


    }
  }

  button {
    width: 100%;
    padding: 10px 15px;
    border-radius: 25px;
    -webkit-appearance: none;
    outline: none;
    margin-left: 0;
    font-size: 1.6rem;
    background-color: $green;
    border: 1px solid $white;
    color: $white;
    transition: all 0.3s;
    @include bp($tabletBP) {
      /*margin-left: -100px;*/
      width: 120px;
    }
    &:hover {
      background-color: $dgray;
    }
  }
}

.curve-top {
  width: 100%;
  position: relative;
  z-index: 2;
  margin-top: 120px;
  @include bp($tabletBP) {
    margin-top: 140px;
  }
  @include bp($desktopBP) {
    margin-top: -90px;
  }
  @include bp(1800) {
    margin-top: -190px;
  }
  @include bp(2100) {
    margin-top: -280px;
  }
}


.meet {
  position: relative;
  z-index: 3;
  // min-height: 400px;
  background-color: $white;
  margin-top: 0%;
  padding-bottom: 5rem;
  @include bp(560) {
    padding-bottom: 8rem;
    margin-top: -3%;
  }
  @include bp(760) {
    margin-top: -6%;
  }

  @include bp(1024) {
    margin-top: -8%;
  }

  .icon {
    text-align: center;
    @include bp($tabletBP) {
      padding-right: 40px;
      text-align: left;
    }

    img {
      display: inline-block;
      width:150px;
    }
  }

  .text {
    text-align: center;
    @include bp($tabletBP) {
      border-left: 1px solid $grey;
      padding-left: 40px;
      text-align: left;
    }
  }

  p {
    color:$dgray;
    font-size: 1.6rem;
    line-height: 2rem;
  }

  article {
    @include grid-collapse($grid--twelve);
  }

  .column--one {
    @include grid-column(12, $grid--twelve);
    margin-bottom: 2em;

    @include grid-media($grid--media-tablet) {
      @include grid-column(3, $grid--twelve);
    }
  }

  .column--two {
    @include grid-column(12, $grid--twelve);
    margin-bottom: 2rem;

    @include grid-media($grid--media-tablet) {
      @include grid-column(9, $grid--twelve);
    }
  }

}


.using {
  position: relative;

  .wrapper {
    background-color: $white;
  }

  article {
    @include grid-collapse($grid--twelve-gutter-6);
  }

  .column {
    @include grid-column(12, $grid--twelve-gutter-6);
    margin-bottom: 4em;

    @include grid-media($grid--media-tablet) {
      @include grid-column(4, $grid--twelve-gutter-6);
    }

    text-align: center;

    img {
      max-width:130px;
      display: inline-block;
      text-align: center;
      margin-bottom: 2rem;
    }
  }

  h1 {
    text-align: center;
    color: $dgray;
    padding-bottom: 3rem;
  }

  p {
    text-align: center;
    color: $dgray;
    margin-bottom: 1rem;
  }


}


.video {
  background: rgb(129,111,237);
  background: linear-gradient(to bottom, rgba(129,111,237,1) 0%,rgba(97,84,206,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#816fed', endColorstr='#6154ce',GradientType=0 );

  .curve-top {
    margin-top: 0;
    margin-bottom: 50px;
  }

  .marginBottom {
    margin-bottom: 4rem;
  }
  article {
    @include grid-collapse($grid--twelve);
  }

  .wrapper {
    position: relative;
    &:after {
      content: '';
      display: block;
      clear: both;
    }
  }

  .column--one {
    width: 100%;
    float: left;
    margin-bottom: 2em;
    text-align: center;
    @include bp($tabletBP) {
      width: 30%;
      text-align: left;
    }

    img {
      position: relative;
      z-index: 2;
        @include bp ($tabletBP, max-width) {
          width: 210px;
        }
    }


  }

  .column--two {
    position: relative;
    width: 100%;
    float: left;
    @include bp($tabletBP) {
      position: absolute;
      top:50%;
      transform: translateY(-50%);
      right:0;
      width: 70%;
    }
    margin-bottom: 2rem;


    .holder {

      @include bp($tabletBP) {
        padding: 0 7%;
      }
    }
  }

  .holder {
    position: relative;
    display: inline-block;

  }

  .phone-video {
    position: absolute;
    top: 11%;
    left: 7%;
    width: 83%;

  @include bp ($tabletBP, max-width) {
    top: 46px;
    left: 15px;
    width: 174px;
  }
  }

  .reverse {
    .column--one {
      float: right;
    }
    .column--two {
      left:0;
    }
    @include bp(860) {
      margin-top: -7rem;
    }
    @include bp ($tabletBP, max-width) {
      display: flex;
      flex-direction: column;
      .column--one {
        order: 1;
      }
      .column--two {
        order: 2;
      }
    }

  }

}

.lock {
  max-width: 900px;
  margin-top: 2rem;
  padding-bottom: 7rem;

  .holder {
    border-radius: 100px;
    background-color: $lpurple;
    padding: 20px;
    @include bp($tabletBP, max-width) {
      border-radius: 20px;

      text-align: center;
    }
  }
  .icon {
    display: inline-block;
    vertical-align: middle;
    padding-right: 30px;
    @include bp($tabletBP, max-width) {
      padding-right: 0px;

    }
    img {
      max-width: 112px;
      width: 100%;

      @include bp($tabletBP, max-width) {
        display: inline-block;
        vertical-align: middle;
        width: 63px;
        padding-right: 15px;

      }
    }
    .mobileOnly {
      @include bp($tabletBP, max-width) {
      display: inline-block;
      vertical-align: middle;
      margin-bottom: 0;
    }
    }
  }
  .text {
    width: 80%;
    vertical-align: middle;
    display: inline-block;
    @include bp($tabletBP, max-width) {

      margin-top: 2rem;
    }
  }
}

.points {
  margin-top: 2.5rem;
  li {
    list-style: none;
    font-size: 1.6rem;
    color: $white;
    padding-left: 30px;
    position: relative;
    margin-bottom: 2rem;
    &:before {
      content: '';
      width: 10px;
      height: 18px;
      background-image: url($img--path + 'arrow.png');
      background-size: 100%;
      position: absolute;
      left:0;
      top:4px;
    }
  }
}

.container {
  position: relative;
  width: 100%;
  @include bp($tabletBP) {
    max-width:800px;
    padding: 0;
    margin: 0 auto;

  }
}

.team {
  // margin-top:-200px;
  @include bp($tabletBP, max-width) {
    padding-top: 3rem;
  }
  background: rgb(28,46,60);
  background: -moz-linear-gradient(top, rgba(28,46,60,1) 0%, rgba(28,46,60,1) 57%, rgba(24,38,50,1) 100%);
  background: -webkit-linear-gradient(top, rgba(28,46,60,1) 0%,rgba(28,46,60,1) 57%,rgba(24,38,50,1) 100%);
  background: linear-gradient(to bottom, rgba(28,46,60,1) 0%,rgba(28,46,60,1) 57%,rgba(24,38,50,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1c2e3c', endColorstr='#182632',GradientType=0 );

  h1 {
    color: $purple2;
    text-align: center;
    margin-bottom: 3rem;
  }
  .curve {
    width: 100%;
    display: block;
    max-width: none;
    padding-top: 3rem;
  }

 article {
   @include grid-collapse($grid--twelve-gutter);
 }



 .column {
   @include grid-column(6, $grid--twelve-gutter);
   @include grid-media($grid--media-tablet) {
     @include grid-column(3, $grid--twelve-gutter);
   }
   text-align: center;
   margin-bottom: 4em;

   img {
     max-width: 211px;
     width: 100%;
     display: inline-block;
     margin-bottom: 2rem;
   }

   .name {
     color: $purple;
     font-weight: bold;
     font-size: 1.6rem;
     display: block;
   }
   .title {
     display: block;
     color: $white;
     font-weight: bold;
     font-size: 1.6rem;
     margin-bottom: 1rem;
   }


   p {
     line-height: 1.6rem;
     font-size: 1.5rem;
   }
}

}


.reviews {
  padding-top: 2rem;
  padding-bottom: 7rem;

  @include bp($tabletBP, max-width) {
    padding-top: 4rem;
  }
  background-color: #1e3140;

  h1 {
    color: $purple2;
    text-align: center;
    margin-bottom: 3rem;
  }
  .wrapper {
    max-width: 800px;
  }
  article {
    @include grid-collapse($grid--twelve-gutter);
  }

  .column {
    @include grid-column(12, $grid--twelve-gutter);
    @include grid-media($grid--media-tablet) {
      @include grid-column(6, $grid--twelve-gutter);
    }
    text-align: center;
    margin-bottom: 4em;
    padding: 0 20px;
  }

  .testi {
    font-style: italic;
    color: $lgrey;
    font-size: 1.5rem;
  }
}

footer {
  background: rgb(129,111,237);
background: -moz-linear-gradient(top, rgba(129,111,237,1) 0%, rgba(97,84,206,1) 100%);
background: -webkit-linear-gradient(top, rgba(129,111,237,1) 0%,rgba(97,84,206,1) 100%);
background: linear-gradient(to bottom, rgba(129,111,237,1) 0%,rgba(97,84,206,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#816fed', endColorstr='#6154ce',GradientType=0 );
  min-height: 400px;
.wrapper {
  overflow: initial;
}
.signup {
  max-width: 700px;
  padding:30px 100px 20px;
  margin: 0 auto;
  background-color: $white;
  border-radius: 150px;
  margin-top: -107px;
  position: relative;
  text-align: center;
  @include bp($tabletBP, max-width) {
    border-radius: 20px;
    padding:30px 30px 10px;

  }
}
.form {

  .email {
    border: 1px solid $grey;
    @include bp($tabletBP) {
      max-width: 470px;
      padding:13px 130px 13px 21px;
    }
  }
  button {
    @include bp($tabletBP) {
      /*margin-left: -126px;*/

    }
  }
}
h1 {
  color: $purple;
}
p {
  color: #1e3140;
  /*font-weight: bold;*/
}

article {
  @include grid-collapse($grid--twelve-gutter);
}

.columns {
  max-width: 600px;
  margin: 0 auto;
  padding:8rem 0rem 0rem;
  text-align: center;
  @include bp($tabletBP) {
    text-align: left;
  }
}

.column {
  @include grid-column(12, $grid--twelve-gutter);
  @include grid-media($grid--media-tablet) {
    @include grid-column(6, $grid--twelve-gutter);
  }
  &:first-child {
    @include bp($tabletBP) {
      padding-left: 13%;

    }
  }
  &:last-child {
    @include bp($tabletBP) {
    padding-left: 5%;
  }
  }
  margin-bottom: 4em;
  p {
    color: $white;
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 1.8rem;
    strong {
      margin-bottom: 1rem;
      display: inline-block;
    }
  }
  a {
    color: $white;
    font-size: 1.5rem;
    text-decoration: underline;
  }
}

.slice {
  overflow: hidden;
  height: 90px;
  margin-top: 2rem;
}

.logo {
  background-color: $purple;
  width: 350px;
  height: 229px;
  border-radius: 50%;
  margin: 0px auto;
  text-align: center;
@include bp($tabletBP, max-width) {
  width: 229px;

}
  img {
    max-width: 126px;
    padding-top: 32px;
    display: inline-block;
  }

}


}
