html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  margin: 0; }

*,
*:after,
*:before {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block; }

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden],
template {
  display: none; }

a {
  background-color: transparent; }

a:active {
  outline: 0; }

a:hover {
  outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b,
strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

mark {
  background: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0;
  display: block; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 1em 40px; }

hr {
  box-sizing: content-box;
  height: 0; }

pre {
  overflow: auto; }

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0; }

button {
  overflow: visible; }

button,
select {
  text-transform: none; }

button, html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled],
html input[disabled] {
  cursor: default; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input {
  line-height: normal; }

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

legend {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto; }

optgroup {
  font-weight: bold; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

/*Align*/
.center {
  text-align: center; }

.left {
  text-align: left; }

.right {
  text-align: center; }

section::after {
  clear: both;
  content: "";
  display: block; }

section article {
  margin-left: 0rem;
  margin-right: 0rem;
  width: calc(100% + 0rem); }

section .column {
  width: calc(100%);
  float: left;
  margin-left: 0rem; }

.grid::after {
  clear: both;
  content: "";
  display: block; }

.columns {
  position: relative; }
  .columns article {
    margin-left: -3rem;
    margin-right: -3rem;
    width: calc(100% + 6rem); }
  .columns .column--one {
    width: calc(100% - 6rem);
    float: left;
    margin-left: 3rem;
    margin-bottom: 2em; }
    @media only screen and (min-width: 768px) {
      .columns .column--one {
        width: calc(33.33333% - 4rem);
        float: left;
        margin-left: 3rem; } }
  .columns .column--two {
    width: calc(100% - 6rem);
    float: left;
    margin-left: 3rem;
    margin-bottom: 2rem; }
    @media only screen and (min-width: 768px) {
      .columns .column--two {
        width: calc(66.66667% - 5rem);
        float: left;
        margin-left: 3rem; } }

@font-face {
  font-family: 'Averta Std';
  src: url("../fonts/AvertaStd-Extrabold.woff2") format("woff2"), url("../fonts/AvertaStd-Extrabold.woff") format("woff");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Averta Std';
  src: url("../fonts/AvertaStd-Thin.woff2") format("woff2"), url("../fonts/AvertaStd-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: 'Averta Std';
  src: url("../fonts/AvertaStd-Bold.woff2") format("woff2"), url("../fonts/AvertaStd-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'Averta Std';
  src: url("../fonts/AvertaStd-Light.woff2") format("woff2"), url("../fonts/AvertaStd-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Averta Std';
  src: url("../fonts/AvertaStd-Black.woff2") format("woff2"), url("../fonts/AvertaStd-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: 'Averta Std';
  src: url("../fonts/AvertaStd-Regular.woff2") format("woff2"), url("../fonts/AvertaStd-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Averta Std';
  src: url("../fonts/AvertaStd-Semibold.woff2") format("woff2"), url("../fonts/AvertaStd-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Averta Std Extrathin';
  src: url("../fonts/AvertaStd-Extrathin.woff2") format("woff2"), url("../fonts/AvertaStd-Extrathin.woff") format("woff");
  font-weight: 100;
  font-style: normal; }

h1,
h2,
h3,
h4 {
  line-height: 1.1;
  font-family: "Averta Std", Arial, sans-serif; }

img {
  display: block;
  width: 100%; }

h1 {
  font-size: 3.2em;
  margin-bottom: 2rem;
  color: #FFFFFF;
  font-weight: bold; }

h2 {
  font-size: 4em;
  margin-bottom: 1em;
  color: #e6302a;
  text-align: center;
  text-transform: uppercase; }

h3 {
  font-size: 2.1em;
  margin-bottom: 0.5em; }
  h3 strong {
    font-weight: 400; }

p {
  font-size: 1.6rem;
  margin-bottom: 1em;
  line-height: 1.6;
  color: #FFFFFF; }
  p:last-of-type {
    margin-bottom: 0; }
  p strong {
    font-weight: bold; }

.center {
  text-align: center; }

a {
  transition: opacity 0.5s; }

.mobileOnly {
  display: block; }
  @media screen and (min-width: 768px) {
    .mobileOnly {
      display: none; } }

.desktopOnly {
  display: none; }
  @media screen and (min-width: 768px) {
    .desktopOnly {
      display: block; } }

html {
  height: 100%;
  font-size: 10px;
  line-height: 1.3; }

body {
  font-family: "Averta Std", Arial, sans-serif;
  background: #FFFFFF;
  color: #000000;
  font-weight: normal;
  height: 100%; }

.wrapper {
  max-width: 1024px;
  margin: 0 auto;
  padding: 0 2em;
  width: 100%;
  overflow: hidden; }
  @media screen and (min-width: 1060px) {
    .wrapper {
      padding: 0; } }

header {
  position: relative;
  background: #816fed;
  background: linear-gradient(to bottom, #816fed -20%, #6154ce 40%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#816fed', endColorstr='#6154ce',GradientType=0 );
  min-height: 660px; }
  header::after {
    clear: both;
    content: "";
    display: block; }
  @media screen and (min-width: 768px) {
    header {
      min-height: 782px; } }
  header:after {
    content: '';
    height: 41px;
    width: 100%;
    position: absolute;
    background-color: #FFFFFF; }
    @media screen and (max-width: 390px) {
      header:after {
        height: 32px; } }

.hero {
  position: relative;
  z-index: 99; }
  .hero .logo {
    background-color: #816fed;
    width: 229px;
    height: 229px;
    border-radius: 50%;
    margin: -125px auto;
    text-align: center; }
    .hero .logo img {
      max-width: 126px;
      padding-top: 150px;
      display: inline-block; }
  .hero .intro {
    margin-top: 15em;
    text-align: center; }

.video-phone {
  position: absolute;
  left: 50%;
  transform: translateX(-50%); }
  .video-phone .phone {
    max-width: 387px; }
    @media screen and (max-width: 679px) {
      .video-phone .phone {
        width: 287px;
        max-width: none; } }

.form {
  text-align: center;
  margin-top: 2em; }
  .form .elements {
    display: block; }
  .form .results {
    display: none;
    max-width: 500px;
    width: 100%;
    margin: 0 auto; }
    .form .results img {
      width: 40px;
      display: inline-block;
      vertical-align: middle; }
    .form .results p {
      font-weight: bold;
      width: calc(100% - 40px);
      display: inline-block;
      vertical-align: middle;
      padding-left: 15px; }
      @media screen and (max-width: 767px) {
        .form .results p {
          padding-top: 10px;
          width: 100%;
          padding-left: 0; } }
  .form .error {
    text-align: center;
    font-weight: normal;
    font-size: 1.2rem;
    margin-top: 5px;
    color: red;
    visibility: hidden; }
  .form .email {
    border-radius: 25px;
    width: 100%;
    padding: 10px 20px;
    border: none;
    -webkit-appearance: none;
    outline: none;
    font-size: 1.6rem;
    color: #b2b2b2;
    margin-bottom: 1rem; }
    @media screen and (min-width: 768px) {
      .form .email {
        padding: 10px 115px 10px 20px;
        margin-bottom: 0rem;
        max-width: 400px; } }
    .form .email::placeholder {
      color: #b2b2b2;
      font-size: 1.6rem; }
  .form button {
    width: 100%;
    padding: 10px 15px;
    border-radius: 25px;
    -webkit-appearance: none;
    outline: none;
    margin-left: 0;
    font-size: 1.6rem;
    background-color: #37aaa8;
    border: 1px solid #FFFFFF;
    color: #FFFFFF;
    transition: all 0.3s; }
    @media screen and (min-width: 768px) {
      .form button {
        /*margin-left: -100px;*/
        width: 120px; } }
    .form button:hover {
      background-color: #5d5d5d; }

.curve-top {
  width: 100%;
  position: relative;
  z-index: 2;
  margin-top: 120px; }
  @media screen and (min-width: 768px) {
    .curve-top {
      margin-top: 140px; } }
  @media screen and (min-width: 1230px) {
    .curve-top {
      margin-top: -90px; } }
  @media screen and (min-width: 1800px) {
    .curve-top {
      margin-top: -190px; } }
  @media screen and (min-width: 2100px) {
    .curve-top {
      margin-top: -280px; } }

.meet {
  position: relative;
  z-index: 3;
  background-color: #FFFFFF;
  margin-top: 0%;
  padding-bottom: 5rem; }
  @media screen and (min-width: 560px) {
    .meet {
      padding-bottom: 8rem;
      margin-top: -3%; } }
  @media screen and (min-width: 760px) {
    .meet {
      margin-top: -6%; } }
  @media screen and (min-width: 1024px) {
    .meet {
      margin-top: -8%; } }
  .meet .icon {
    text-align: center; }
    @media screen and (min-width: 768px) {
      .meet .icon {
        padding-right: 40px;
        text-align: left; } }
    .meet .icon img {
      display: inline-block;
      width: 150px; }
  .meet .text {
    text-align: center; }
    @media screen and (min-width: 768px) {
      .meet .text {
        border-left: 1px solid #eaeaea;
        padding-left: 40px;
        text-align: left; } }
  .meet p {
    color: #5d5d5d;
    font-size: 1.6rem;
    line-height: 2rem; }
  .meet article {
    margin-left: 0rem;
    margin-right: 0rem;
    width: calc(100% + 0rem); }
  .meet .column--one {
    width: calc(100%);
    float: left;
    margin-left: 0rem;
    margin-bottom: 2em; }
    @media only screen and (min-width: 768px) {
      .meet .column--one {
        width: calc(25%);
        float: left;
        margin-left: 0rem; } }
  .meet .column--two {
    width: calc(100%);
    float: left;
    margin-left: 0rem;
    margin-bottom: 2rem; }
    @media only screen and (min-width: 768px) {
      .meet .column--two {
        width: calc(75%);
        float: left;
        margin-left: 0rem; } }

.using {
  position: relative; }
  .using .wrapper {
    background-color: #FFFFFF; }
  .using article {
    margin-left: -6rem;
    margin-right: -6rem;
    width: calc(100% + 12rem); }
  .using .column {
    width: calc(100% - 12rem);
    float: left;
    margin-left: 6rem;
    margin-bottom: 4em;
    text-align: center; }
    @media only screen and (min-width: 768px) {
      .using .column {
        width: calc(33.33333% - 8rem);
        float: left;
        margin-left: 6rem; } }
    .using .column img {
      max-width: 130px;
      display: inline-block;
      text-align: center;
      margin-bottom: 2rem; }
  .using h1 {
    text-align: center;
    color: #5d5d5d;
    padding-bottom: 3rem; }
  .using p {
    text-align: center;
    color: #5d5d5d;
    margin-bottom: 1rem; }

.video {
  background: #816fed;
  background: linear-gradient(to bottom, #816fed 0%, #6154ce 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#816fed', endColorstr='#6154ce',GradientType=0 ); }
  .video .curve-top {
    margin-top: 0;
    margin-bottom: 50px; }
  .video .marginBottom {
    margin-bottom: 4rem; }
  .video article {
    margin-left: 0rem;
    margin-right: 0rem;
    width: calc(100% + 0rem); }
  .video .wrapper {
    position: relative; }
    .video .wrapper:after {
      content: '';
      display: block;
      clear: both; }
  .video .column--one {
    width: 100%;
    float: left;
    margin-bottom: 2em;
    text-align: center; }
    @media screen and (min-width: 768px) {
      .video .column--one {
        width: 30%;
        text-align: left; } }
    .video .column--one img {
      position: relative;
      z-index: 2; }
      @media screen and (max-width: 767px) {
        .video .column--one img {
          width: 210px; } }
  .video .column--two {
    position: relative;
    width: 100%;
    float: left;
    margin-bottom: 2rem; }
    @media screen and (min-width: 768px) {
      .video .column--two {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        width: 70%; } }
    @media screen and (min-width: 768px) {
      .video .column--two .holder {
        padding: 0 7%; } }
  .video .holder {
    position: relative;
    display: inline-block; }
  .video .phone-video {
    position: absolute;
    top: 11%;
    left: 7%;
    width: 83%; }
    @media screen and (max-width: 767px) {
      .video .phone-video {
        top: 46px;
        left: 15px;
        width: 174px; } }
  .video .reverse .column--one {
    float: right; }
  .video .reverse .column--two {
    left: 0; }
  @media screen and (min-width: 860px) {
    .video .reverse {
      margin-top: -7rem; } }
  @media screen and (max-width: 767px) {
    .video .reverse {
      display: flex;
      flex-direction: column; }
      .video .reverse .column--one {
        order: 1; }
      .video .reverse .column--two {
        order: 2; } }

.lock {
  max-width: 900px;
  margin-top: 2rem;
  padding-bottom: 7rem; }
  .lock .holder {
    border-radius: 100px;
    background-color: #7367d5;
    padding: 20px; }
    @media screen and (max-width: 767px) {
      .lock .holder {
        border-radius: 20px;
        text-align: center; } }
  .lock .icon {
    display: inline-block;
    vertical-align: middle;
    padding-right: 30px; }
    @media screen and (max-width: 767px) {
      .lock .icon {
        padding-right: 0px; } }
    .lock .icon img {
      max-width: 112px;
      width: 100%; }
      @media screen and (max-width: 767px) {
        .lock .icon img {
          display: inline-block;
          vertical-align: middle;
          width: 63px;
          padding-right: 15px; } }
    @media screen and (max-width: 767px) {
      .lock .icon .mobileOnly {
        display: inline-block;
        vertical-align: middle;
        margin-bottom: 0; } }
  .lock .text {
    width: 80%;
    vertical-align: middle;
    display: inline-block; }
    @media screen and (max-width: 767px) {
      .lock .text {
        margin-top: 2rem; } }

.points {
  margin-top: 2.5rem; }
  .points li {
    list-style: none;
    font-size: 1.6rem;
    color: #FFFFFF;
    padding-left: 30px;
    position: relative;
    margin-bottom: 2rem; }
    .points li:before {
      content: '';
      width: 10px;
      height: 18px;
      background-image: url("../img/arrow.png");
      background-size: 100%;
      position: absolute;
      left: 0;
      top: 4px; }

.container {
  position: relative;
  width: 100%; }
  @media screen and (min-width: 768px) {
    .container {
      max-width: 800px;
      padding: 0;
      margin: 0 auto; } }

.team {
  background: #1c2e3c;
  background: -moz-linear-gradient(top, #1c2e3c 0%, #1c2e3c 57%, #182632 100%);
  background: -webkit-linear-gradient(top, #1c2e3c 0%, #1c2e3c 57%, #182632 100%);
  background: linear-gradient(to bottom, #1c2e3c 0%, #1c2e3c 57%, #182632 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1c2e3c', endColorstr='#182632',GradientType=0 ); }
  @media screen and (max-width: 767px) {
    .team {
      padding-top: 3rem; } }
  .team h1 {
    color: #a294f7;
    text-align: center;
    margin-bottom: 3rem; }
  .team .curve {
    width: 100%;
    display: block;
    max-width: none;
    padding-top: 3rem; }
  .team article {
    margin-left: -3rem;
    margin-right: -3rem;
    width: calc(100% + 6rem); }
  .team .column {
    width: calc(50% - 4.5rem);
    float: left;
    margin-left: 3rem;
    text-align: center;
    margin-bottom: 4em; }
    @media only screen and (min-width: 768px) {
      .team .column {
        width: calc(25% - 3.75rem);
        float: left;
        margin-left: 3rem; } }
    .team .column img {
      max-width: 211px;
      width: 100%;
      display: inline-block;
      margin-bottom: 2rem; }
    .team .column .name {
      color: #816fed;
      font-weight: bold;
      font-size: 1.6rem;
      display: block; }
    .team .column .title {
      display: block;
      color: #FFFFFF;
      font-weight: bold;
      font-size: 1.6rem;
      margin-bottom: 1rem; }
    .team .column p {
      line-height: 1.6rem;
      font-size: 1.5rem; }

.reviews {
  padding-top: 2rem;
  padding-bottom: 7rem;
  background-color: #1e3140; }
  @media screen and (max-width: 767px) {
    .reviews {
      padding-top: 4rem; } }
  .reviews h1 {
    color: #a294f7;
    text-align: center;
    margin-bottom: 3rem; }
  .reviews .wrapper {
    max-width: 800px; }
  .reviews article {
    margin-left: -3rem;
    margin-right: -3rem;
    width: calc(100% + 6rem); }
  .reviews .column {
    width: calc(100% - 6rem);
    float: left;
    margin-left: 3rem;
    text-align: center;
    margin-bottom: 4em;
    padding: 0 20px; }
    @media only screen and (min-width: 768px) {
      .reviews .column {
        width: calc(50% - 4.5rem);
        float: left;
        margin-left: 3rem; } }
  .reviews .testi {
    font-style: italic;
    color: #b2b2b2;
    font-size: 1.5rem; }

footer {
  background: #816fed;
  background: -moz-linear-gradient(top, #816fed 0%, #6154ce 100%);
  background: -webkit-linear-gradient(top, #816fed 0%, #6154ce 100%);
  background: linear-gradient(to bottom, #816fed 0%, #6154ce 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#816fed', endColorstr='#6154ce',GradientType=0 );
  min-height: 400px; }
  footer .wrapper {
    overflow: initial; }
  footer .signup {
    max-width: 700px;
    padding: 30px 100px 20px;
    margin: 0 auto;
    background-color: #FFFFFF;
    border-radius: 150px;
    margin-top: -107px;
    position: relative;
    text-align: center; }
    @media screen and (max-width: 767px) {
      footer .signup {
        border-radius: 20px;
        padding: 30px 30px 10px; } }
  footer .form .email {
    border: 1px solid #eaeaea; }
    @media screen and (min-width: 768px) {
      footer .form .email {
        max-width: 470px;
        padding: 13px 130px 13px 21px; } }
  @media screen and (min-width: 768px) {
    footer .form button {
      /*margin-left: -126px;*/ } }
  footer h1 {
    color: #816fed; }
  footer p {
    color: #1e3140;
    /*font-weight: bold;*/ }
  footer article {
    margin-left: -3rem;
    margin-right: -3rem;
    width: calc(100% + 6rem); }
  footer .columns {
    max-width: 600px;
    margin: 0 auto;
    padding: 8rem 0rem 0rem;
    text-align: center; }
    @media screen and (min-width: 768px) {
      footer .columns {
        text-align: left; } }
  footer .column {
    width: calc(100% - 6rem);
    float: left;
    margin-left: 3rem;
    margin-bottom: 4em; }
    @media only screen and (min-width: 768px) {
      footer .column {
        width: calc(50% - 4.5rem);
        float: left;
        margin-left: 3rem; } }
    @media screen and (min-width: 768px) {
      footer .column:first-child {
        padding-left: 13%; } }
    @media screen and (min-width: 768px) {
      footer .column:last-child {
        padding-left: 5%; } }
    footer .column p {
      color: #FFFFFF;
      font-weight: normal;
      font-size: 1.5rem;
      line-height: 1.8rem; }
      footer .column p strong {
        margin-bottom: 1rem;
        display: inline-block; }
    footer .column a {
      color: #FFFFFF;
      font-size: 1.5rem;
      text-decoration: underline; }
  footer .slice {
    overflow: hidden;
    height: 90px;
    margin-top: 2rem; }
  footer .logo {
    background-color: #816fed;
    width: 350px;
    height: 229px;
    border-radius: 50%;
    margin: 0px auto;
    text-align: center; }
    @media screen and (max-width: 767px) {
      footer .logo {
        width: 229px; } }
    footer .logo img {
      max-width: 126px;
      padding-top: 32px;
      display: inline-block; }
