/*Align*/

.center {
    text-align: center;
}

.left {
    text-align: left;
}

.right {
    text-align: center;
}

// Grid Settings
$grid--twelve: (columns: 12, gutter: 0rem);
$grid--twelve-gutter: (columns: 12, gutter: 3rem);
$grid--twelve-gutter-6: (columns: 12, gutter: 6rem);

$grid--media-tablet: (media:$tabletBPgrid);



// Default Grid
section {
   @include grid-container;
   article {
     @include grid-collapse($grid--twelve);
   }
   .column{
     @include grid-column(12, $grid--twelve);
   }
}

.grid {
  @include grid-container;
}


.columns {
  position: relative;
  article {
    @include grid-collapse($grid--twelve-gutter);
  }

  .column--one {
    @include grid-column(12, $grid--twelve-gutter);
    margin-bottom: 2em;

    @include grid-media($grid--media-tablet) {
      @include grid-column(4, $grid--twelve-gutter);
    }
  }

  .column--two {
    @include grid-column(12, $grid--twelve-gutter);
    margin-bottom: 2rem;

    @include grid-media($grid--media-tablet) {
      @include grid-column(8, $grid--twelve-gutter);
    }
  }
}
