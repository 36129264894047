@font-face {
    font-family: 'Averta Std';
    src: url('../fonts/AvertaStd-Extrabold.woff2') format('woff2'),
        url('../fonts/AvertaStd-Extrabold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Averta Std';
    src: url('../fonts/AvertaStd-Thin.woff2') format('woff2'),
        url('../fonts/AvertaStd-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Averta Std';
    src: url('../fonts/AvertaStd-Bold.woff2') format('woff2'),
        url('../fonts/AvertaStd-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Averta Std';
    src: url('../fonts/AvertaStd-Light.woff2') format('woff2'),
        url('../fonts/AvertaStd-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Averta Std';
    src: url('../fonts/AvertaStd-Black.woff2') format('woff2'),
        url('../fonts/AvertaStd-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Averta Std';
    src: url('../fonts/AvertaStd-Regular.woff2') format('woff2'),
        url('../fonts/AvertaStd-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Averta Std';
    src: url('../fonts/AvertaStd-Semibold.woff2') format('woff2'),
        url('../fonts/AvertaStd-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Averta Std Extrathin';
    src: url('../fonts/AvertaStd-Extrathin.woff2') format('woff2'),
        url('../fonts/AvertaStd-Extrathin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}
