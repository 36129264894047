//Path
$asset--path: "../";
$img--path: $asset--path + "img/";



// COLORS
$black: #000000;
$white: #FFFFFF;
$grey: #eaeaea;
$lgrey: #b2b2b2;
$darkgrey: #6f6f6f;
$dgray: #5d5d5d;
$red: #e6302a;
$yellow: #ffec00;
$green: #37aaa8;
$purple: #816fed;
$lpurple: #7367d5;
$purple2: #a294f7;


// Fonts
$font--family: 'Averta Std', Arial, sans-serif;
$font--regular: 'GeomSlab703W01-MediumCd', Arial, sans-serif;
$font--light: 'Geom Slab_703 W01 Light',Arial, sans-serif;
$font--bold: 'GeomSlab703W01-BoldCd',Arial, sans-serif;
$font--black: 'GeomSlab703W01-ExtraBol1120033',Arial, sans-serif;
$font--opensans: 'Open Sans',Arial, sans-serif;

// Fonts WEIGHTS
$font--reg: 400;
$fontSize--default: 1.6rem;
$fontSize--small: 1.4rem;
$lineHeight--default: 1.1;
$lineHeight--normal: 1.6;

// Media Queries Breakpoints
// Latest breakpoint variables
$mobileBP: 321;
$tabletBP: 768;
$phabletBP: 680;
$laptopBP: 992;
$desktopBP: 1230;

// Grid  Breakpoints
$mobileBPgrid: 321px;
$tabletBPgrid: 768px;
$phabletBPgrid: 680px;
$laptopBPgrid: 992px;
$desktopBPgrid: 1230px;


$from-phablet: new-breakpoint(min-width 480px);
$from-mobile-min: new-breakpoint(max-width 321px);
$from-mobile-x: new-breakpoint(min-width 322px max-width 375px);
$from-mobile-x-onwards: new-breakpoint(min-width 375px);
$from-tablet: new-breakpoint(min-width 768px);
$from-mobile-ls: new-breakpoint(min-width 720px);
$from-tablet-ls: new-breakpoint(min-device-width 480px max-device-width 1024px -webkit-min-device-pixel-ratio 1);
$from-desktop-sml: new-breakpoint(min-width 769px);
// $from-desktop-mid : new-breakpoint(min-width 1200px);
$from-tablet-max: new-breakpoint(max-width 767px);
$from-desktop-mid: new-breakpoint(min-width 1240px);
$from-desktop-lrg: new-breakpoint(min-width 1800px);
$from-desktop-nor: new-breakpoint(min-width 1100px);
$from-desktop: new-breakpoint(min-width 769px);
$from-desktop-768: new-breakpoint(min-width 600px max-height 768px);



// Wrapper Width
  $max-width: 1024px;

// MISC
$radius: 0.2rem;
$separator: 4rem;
