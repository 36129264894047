@mixin center-h--unk-remove {
    position: relative;
    top: 0;
    @include prefix(transform, none, 'webkit' 'ms');
}

// Center Any Element
// Usage @include centerElement(Veritcal or Horizontal or Both)
@mixin centerElement($method){
  @if($method == "horizontal") {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  @else if ($method == "vertical") {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  @else if ($method == "both") {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

// This is column mixins.
@mixin col ($col, $sum, $gap: 1%, $align: top, $first: false, $last: false, $type:inline-block, $mobile:false) {
    width: percentage($col/$sum);
    display: $type;
    padding-left: if($first, 0, $gap);
    padding-right: if($last, 0, $gap);

    &:first-child,
    :last-child {
        padding-left: if($mobile, $gap, 0);
        padding-right: if($mobile, $gap, 0);
    }

}

// Latest breakpoint mixin
// /* BREAK POINTS */
//
// /* -- min-width by default
//    -- if 2nd parameter is 'max-width', max-width used instead
//    -- if $until is added, $point becomes min-width and $until is max-width */
@mixin bp($point, $minmax : min-width, $until:null) {
	@if($minmax == "max-width") {

		$point : $point - 1;

	}
	@if($until) {
    // $point : $point + 1;
		@media screen and ($minmax: #{$point}px) and (max-width: #{$until}px) { @content; }

	} @else {

		@media screen and ($minmax: #{$point}px) { @content; }

	}
}


// CENTRED CONTENT
@mixin centred($centredInner) {
    & {
        text-align: center;
        height: 100%;

        &:before {
            @include inline-block;
            content: '';
            height: 100%;
        }

        .#{$centredInner} {
            @include inline-block;
        }

    }

}

// Background Image Repeater
@mixin bgimg($img) {
    background-image: url($img--path + $img + ".png");
    background-image: url($img--path + $img + ".svg");
}

// /*Button Arrow*/
// @mixin ctaArrow($img, $color, $dlr, $textcolor) {
//   background-image: url($img--path + "arrow-circle-left.png");
//   background-repeat: no-repeat;
//   background-size: contain;
//   background-position: right;
// }

// ANIMATED PNG
// /* Mixin to replicate an animated GIF using Keyframes and a PNG Sprite image.
//
//     $numberOfSteps     = the number of frames to step between (no limit to number of steps)
//     $animName         = purely used as the animation name to avoid duplicate animations
//     $imageSize         = the width or height of the PNG image which needs to be shown in each frame
//     $duration         = the total time for the animation in seconds
//     $playCount        = the way the animation is played (linear, infinite, inout)
//
//     ** requires auto-prefixer to support all modern browsers
//     ** Requires 'compass/css3' */

// @mixin animatedPNG($numberOfSteps, $animName, $imageSize, $animDirection, $duration, $playCount) {
//
//     @include animation($animName $duration $playCount);
//
//     /* places the keyframes animation outside of the selector */
//     @at-root {
//
//         @include keyframes($animName) {
//
//             0% { background-position:0 0; }
//             $stepCount: $numberOfSteps;
//             $step: 1;
//
//             @while $stepCount > 1 {
//
//                 $keyframePercentage: (100 / $numberOfSteps) * $step;
//
//                 $imagePos: 0;
//
//                 @if $step > 1 {
//                     $imagePos: $imageSize * ($step - 1);
//                 }
//
//                 $step: $step + 1;
//                 $stepCount: $stepCount - 1;
//
//                 @if $animDirection == horizontal {
//                     #{$keyframePercentage + '%'} {
//                         background-position: unquote('-' + $imagePos + 'px') 0;
//                     }
//
//                     #{$keyframePercentage + .00001 + '%'} {
//                         background-position: unquote('-' + ($imagePos + $imageSize) + 'px') 0;
//                     }
//                 } @else if $animDirection == vertical {
//                     #{$keyframePercentage + '%'} {
//                         background-position: 0 unquote('-' + $imagePos + 'px');
//                     }
//
//                     #{$keyframePercentage + .00001 + '%'} {
//                         background-position: 0 unquote('-' + ($imagePos + $imageSize) + 'px');
//                     }
//                 }
//
//             }
//
//             $finalPosition: $imageSize * ($numberOfSteps - 1);
//
//             @if $animDirection == horizontal {
//                 100% { background-position: unquote('-' + $finalPosition + 'px') 0; }
//             } @else if $animDirection == vertical {
//                 100% { background-position: 0 unquote('-' + $finalPosition + 'px'); }
//             }
//         }
//
//     }
//
// }

// /* ANIMATED PNG */
// /* Mixin to replicate an animated GIF using Keyframes and a PNG Sprite image.
//
//     $numberOfSteps     = the number of frames to step between (no limit to number of steps)
//     $animName         = purely used as the animation name to avoid duplicate animations
//     $imageSize         = the width or height of the PNG image which needs to be shown in each frame
//     $duration         = the total time for the animation in seconds
//     $playCount        = the way the animation is played (linear, infinite, inout)
//
//     ** requires auto-prefixer to support all modern browsers
//     ** Requires 'compass/css3' */
@mixin animatedImage($numberOfSteps, $animName, $imageSize, $animDirection, $duration, $playCount, $responsive:true) {

    animation: $animName $duration $playCount;
    @if $responsive == true {
        background-size: unquote($numberOfSteps * 100 + '%');
    }

    // /* places the keyframes animation outside of the selector */
    @at-root {
         @keyframes #{$animName}{
            0% {
                background-position: 0 0;
            }
            $stepCount: $numberOfSteps;
            $step: 1;
            @while $stepCount > 1 {

                $keyframePercentage: (100 / $numberOfSteps) * $step;

                $imagePos: 0;
                @if $step > 1 {
                    $imagePos: $imageSize * ($step - 1);
                }

                $step: $step + 1;
                $stepCount: $stepCount - 1;
                @if $responsive == true {
                     @if $animDirection == horizontal{
                        #{$keyframePercentage + '%'} {
                            background-position: percentage(($step - 2) / ($numberOfSteps - 1)) 0;
                        }

                        #{$keyframePercentage + .00001 + '%'} {
                            background-position: percentage(($step - 1) / ($numberOfSteps - 1)) 0;
                        }
                    }
                    @else if $animDirection == vertical {
                        #{$keyframePercentage + '%'} {
                            background-position: 0 percentage(($step - 1) / ($numberOfSteps - 1));
                        }

                        #{$keyframePercentage + .00001 + '%'} {
                            background-position: 0 percentage($step / ($numberOfSteps - 1));
                        }
                    }

                }
                @else {
                     @if $animDirection == horizontal{
                        #{$keyframePercentage + '%'} {
                            background-position: unquote('-' + $imagePos + 'px') 0;
                        }

                        #{$keyframePercentage + .00001 + '%'} {
                            background-position: unquote('-' + ($imagePos + $imageSize) + 'px') 0;
                        }
                    }
                    @else if $animDirection == vertical {
                        #{$keyframePercentage + '%'} {
                            background-position: 0 unquote('-' + $imagePos + 'px');
                        }

                        #{$keyframePercentage + .00001 + '%'} {
                            background-position: 0 unquote('-' + ($imagePos + $imageSize) + 'px');
                        }
                    }

                }

            }

            $finalPosition: $imageSize * ($numberOfSteps - 1);
            @if $responsive == true {
                 @if $animDirection == horizontal{
                    100% {
                        background-position: 100% 0;
                    }
                }
                @else if $animDirection == vertical {
                    100% {
                        background-position: 0 100%;
                    }
                }

            }
            @else {
                 @if $animDirection == horizontal{
                    100% {
                        background-position: unquote('-' + $finalPosition + 'px') 0;
                    }
                }
                @else if $animDirection == vertical {
                    100% {
                        background-position: 0 unquote('-' + $finalPosition + 'px');
                    }
                }

            }

        }

    }

}
