h1,
h2,
h3,
h4 {
    line-height: $lineHeight--default;
    font-family: $font--family;
}

img {
  display: block;
  width: 100%;
}

h1 {
    font-size: 3.2em;
    margin-bottom: 2rem;
    color: $white;
    font-weight: bold;
}

h2 {
    font-size: 4em;
    margin-bottom: 1em;
    color: $red;
    text-align: center;
    text-transform:uppercase;

}

h3 {
    font-size: 2.1em;
    margin-bottom: 0.5em;
    strong {
        font-weight: 400;
    }
}

h4 {
    //font-size : 1.6em;
}

p {
    font-size: $fontSize--default;
    margin-bottom: 1em;
    line-height: $lineHeight--normal;
    color: $white;

    &:last-of-type {
        margin-bottom: 0;
    }

    strong {
        font-weight: bold;
    }
}

.center {
  text-align: center;
}

a {
    transition: opacity 0.5s;

    &:hover {
        // opacity: 0.8;
    }
}


.mobileOnly {
  display: block;
  @include bp($tabletBP) {
  display: none;
  }
}
.desktopOnly {
  display: none;
  @include bp($tabletBP) {
  display: block; 
  }
}
